import { createContext, useState, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import AuthApi from 'lib/api/auth'
// import { useLocalStorage } from "@/utils/use-local-storage"

const AuthStateContext = createContext({})

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const accessToken = Cookies.get('banana_token')

  const localData = JSON.parse(
    typeof window !== 'undefined' && localStorage.getItem('userData')
  )

  const setUserDataLocal = (user) => {
    localStorage.setItem('userData', JSON.stringify(user))
  }

  useEffect(() => {
    async function loadUserFromCookies() {
      if (accessToken && localData !== undefined) setUser(localData)
      else if (accessToken) {
        try {
          const { data: userData } =
            accessToken && (await new AuthApi(accessToken).getCurrentUser())
          if (userData.id) {
            setUser(userData)
            setUserDataLocal(userData)
          } else loadUserFromCookies()
        } catch (err) {
          setLogout()
          console.log('token finish', err)
        }
      }
      setLoading(false)
    }
    loadUserFromCookies()
  }, [])

  const setLogin = async (tokens) => {
    const { token, refresh_token } = tokens
    try {
      const { data: userData } = await new AuthApi(token).getCurrentUser()
      setUser(userData)
      setUserDataLocal(userData)
      Cookies.set('banana_token', token)
      Cookies.set('refresh_token', refresh_token)
    } catch (err) {
      // noop
      console.log('Login hiihed aldaa garlaa')
    }
  }

  const setLogout = async () => {
    console.log('logout')
    Cookies.remove('banana_token')
    Cookies.remove('refresh_token')
    typeof window !== 'undefined' && localStorage.removeItem('userData')
    setUser(null)
  }

  return (
    <AuthStateContext.Provider
      value={{ isAuthenticated: !!user, user, loading, setLogin, setLogout }}
    >
      {children}
    </AuthStateContext.Provider>
  )
}

export const useAuthState = () => useContext(AuthStateContext)
