import axios from 'axios'
import { useApiHandler, useApiHandlerHydra } from 'actions'

const headers = {
  'Content-Type': 'application/merge-patch+json',
}

const createCart = (data) =>
  axios.post(`${process.env.BASE_URL}shop/orders`, data)

const merchantAddtemToCart = (data) => axios.post(`${process.env.BASE_URL}shop/merchant/add-item`, data)  
const addCartItemsCart = (data, orderToken, accessToken) =>
  axios.patch(`${process.env.BASE_URL}shop/orders/${orderToken}/items`, data, {
    headers: {
      'Content-Type': 'application/merge-patch+json',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  })
const multiCartItemsCart = (data, orderToken, accessToken) =>
  axios.patch(
    `${process.env.BASE_URL}shop/orders/${orderToken}/multiple/items`,
    data,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    }
  )
const updateCart = (data, orderToken, accessToken) =>
  axios.patch(
    `${process.env.BASE_URL}shop/orders/${orderToken}/change-quantity`,
    data,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    }
  )
const deleteCart = (data, orderToken, accessToken) =>
  axios.patch(`${process.env.BASE_URL}shop/orders/${orderToken}/remove`, data, {
    headers: {
      'Content-Type': 'application/merge-patch+json',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  })
const removeCart = (orderToken, accessToken) =>
  axios.patch(
    `${process.env.BASE_URL}shop/orders/${orderToken}/items/remove`,
    {},
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    }
  )

const productPriceUpdate = (data) => axios.post(
  `${process.env.BASE_URL}shop/merchant/product/price/change`,
  data
);  

const orderCancel = (orderNumber, accessToken) => axios.get(
  `${process.env.BASE_URL}shop/merchant/order/${orderNumber}/cancel`,
  {
    headers: {
      'Content-Type': 'application/merge-patch+json',
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  }
);  

const addAddress = (data, orderToken, accessToken) =>
  axios.patch(
    `${process.env.BASE_URL}shop/orders/${orderToken}/address`,
    data,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    }
  )

const addShipment = (data, orderToken, accessToken, shipmentId) =>
  axios.patch(
    `${process.env.BASE_URL}shop/orders/${orderToken}/shipments/${shipmentId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    }
  )

export const useCreateCart = () => useApiHandler(createCart)
export const useMerchantAddItemToCart = () => useApiHandler(merchantAddtemToCart)
export const useAddItemsCart = () => useApiHandler(addCartItemsCart)
export const useMultiItemsCart = () => useApiHandler(multiCartItemsCart)
export const useUpdateCart = () => useApiHandler(updateCart)
export const useProductUpdatePrice = () => useApiHandler(productPriceUpdate)
export const useRemoveCart = () => useApiHandler(removeCart)
export const useDeleteCart = () => useApiHandler(deleteCart)
export const useOrderCancel = () => useApiHandler(orderCancel)
export const useAddAddress = () => useApiHandlerHydra(addAddress)
export const useAddShipment = () => useApiHandlerHydra(addShipment)
