import { Component } from 'react'
import Image from 'next/image'

class Loader extends Component {
  render() {
    return (
      <div
        className="web-loader d-flex align-items-center justify-content-center"
        style={{ zIndex: 10000 }}
      >
        <Image
          src="/banana_loader.gif"
          className="animated"
          layout={'fixed'}
          width={100}
          height={100}
          quality={50}
        />
      </div>
    )
  }
}

export default Loader
