import { createContext, useState, useContext } from 'react'

const DataContext = createContext()

export const DataProvider = ({ children }) => {
  const [storage, setStorage] = useState({})

  const setValues = (values) => {
    setStorage((prevData) => ({
      ...prevData,
      ...values,
    }))
  }

  return (
    <DataContext.Provider value={{ storage, setValues }}>
      {children}
    </DataContext.Provider>
  )
}

export const useData = () => useContext(DataContext)
