import {
  createContext,
  useReducer,
  useEffect,
  useContext,
  useState,
} from 'react'
import Cookies from 'js-cookie'
import CartApi from 'lib/api/carts'
import WishListApi from '@/lib/api/wishlist'
import { useCreateCart } from '@/actions/carts'
import lodash from 'lodash'

const CartStateContext = createContext({})
const CartDispatchContext = createContext({})

const SET_CART = 'SET_CART'
const SET_CART_COUPON = 'SET_CART_COUPON'
const SET_CART_CHANGE = 'SET_CART_CHANGE'
const SET_CART_REMOVE = 'SET_CART_REMOVE'
const SET_WISH = 'SET_WISH'
const RESET = 'RESET'

const initialState = {
  items: [],
  currentCarts: [],
  count: 0,
  wishList: [],
  wishCount: 0,
  cartLoading: true,
}

const reducer = (state, action) => {
  switch (action.type) {
    case SET_CART_CHANGE:
      const count1 =
        action.payload &&
        lodash.values(action.payload?.items).reduce(function (cnt, item) {
          return cnt + parseInt(item.quantity)
        }, 0)
      return {
        ...state,
        items: lodash.values(action.payload?.items),
        currentCarts: action.payload,
        count: count1,
        cartLoading: action.loading,
      }

    case SET_CART:
      const count =
        action.payload &&
        lodash.values(action.payload?.items)?.reduce(function (cnt, item) {
          return cnt + parseInt(item.quantity)
        }, 0)
      return {
        ...state,
        items: lodash.values(action.payload?.items),
        currentCarts: action.payload,
        count: count,
        cartLoading: action.loading,
      }

    case SET_CART_COUPON:
      const count3 =
        action.payload &&
        lodash.values(action.payload?.items).reduce(function (cnt, item) {
          return cnt + parseInt(item.quantity)
        }, 0)
      return {
        ...state,
        items: lodash.values(action.payload?.items),
        currentCarts: lodash.values(action.payload),
        count: count3,
        cartLoading: action.loading,
      }

    case SET_CART_REMOVE:
      const count2 =
        action.payload &&
        lodash.values(action.payload?.items)?.reduce(function (cnt, item) {
          return cnt + parseInt(item.quantity)
        }, 0)
      return {
        ...state,
        items: lodash.values(action.payload?.items),
        currentCarts: lodash.values(action.payload),
        count: count2,
        cartLoading: action.loading,
      }

    case SET_WISH:
      const wishCount = action.payload ? action.payload.length : 0
      return {
        ...state,
        wishList: lodash.values(action.payload),
        wishCount: wishCount,
      }
    case RESET:
      return initialState
    default:
      throw new Error(`Unknown action: ${action.type}`)
  }
}

export const CartProvider = ({ children }) => {
  const orderToken = Cookies.get('banana_order_token')
  const accessToken = Cookies.get('banana_token')
  const [state, dispatch] = useReducer(reducer, initialState)
  const [token, setToken] = useState(orderToken)
  const [createCart] = useCreateCart()
  let componentMounted = true
  useEffect(() => {
    if (accessToken) {
      getWish()
    }
  }, [accessToken])

  useEffect(() => {
    getCart()
  }, [])

  const getCart = async () => {
    if (token) {
      try {
        const cartJson =
          token && (await new CartApi(accessToken).getCartsAll(token))
        const carts = cartJson.data
        dispatch({ type: SET_CART, payload: carts, loading: false })
      } catch (err) {
        const createCartResult = await createCart({})
        if (!createCartResult) {
          return
        }
        Cookies.set('banana_order_token', createCartResult?.tokenValue)
        setToken(createCartResult?.tokenValue)
      }
    } else {
      console.log('token bhgui orson')
      const createCartResult = await createCart({})
      if (!createCartResult) {
        return
      }

      Cookies.set('banana_order_token', createCartResult?.tokenValue)
      setToken(createCartResult?.tokenValue)

      const cartJson = await new CartApi().getCartsAll(
        createCartResult?.tokenValue
      )
      const carts = cartJson.data
      dispatch({ type: SET_CART, payload: carts, loading: false })
    }
  }
  const setCartChange = async (data) => {
    if (data) {
      dispatch({ type: SET_CART_CHANGE, payload: data, loading: false })
    }
  }

  const setCartCoupon = async (data) => {
    if (data) {
      dispatch({ type: SET_CART_COUPON, payload: data, loading: false })
    }
  }

  const setCartRemove = async (data) => {
    if (data) {
      dispatch({ type: SET_CART_REMOVE, payload: data, loading: false })
    }
  }

  const setCart = async (data) => {
    if (data) {
      dispatch({ type: SET_CART, payload: data, loading: false })
    }
  }

  const getWish = async () => {
    try {
      const wishJson = await new WishListApi(accessToken).getWishListAll()
      const wishData = wishJson?.data?.wishlistProducts
      dispatch({ type: SET_WISH, payload: wishData })
    } catch (err) {
      console.log('Нэвтрээгүй байна.')
      // noop
    }
  }

  const setWish = async (data) => {
    let payload = data?.wishlistProducts
    dispatch({ type: SET_WISH, payload })
  }

  const reset = async () => dispatch({ type: RESET })

  return (
    <CartDispatchContext.Provider
      value={{
        setCart,
        reset,
        setWish,
        getWish,
        setCartChange,
        setCartRemove,
        setCartCoupon,
      }}
    >
      <CartStateContext.Provider value={{ ...state }}>
        {children}
      </CartStateContext.Provider>
    </CartDispatchContext.Provider>
  )
}

export const useCartState = () => useContext(CartStateContext)
export const useCartDispatch = () => useContext(CartDispatchContext)
